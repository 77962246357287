import { Controller } from '@hotwired/stimulus';
import { apiClient } from '../../shared/apiClient';
import { disableButton, enableButton } from '../../shared/util';

export default class extends Controller {
  static targets = [];

  connect() {}

  async sendDetails(e) {
    e.preventDefault();
    const sendDetailsBtn = e.target.closest('button');
    const errorDisplay = sendDetailsBtn.parentElement.querySelector('.invalid-feedback');
    errorDisplay.classList.remove('d-block');
    disableButton(sendDetailsBtn);
    const { branchSlug, listingSlug } = sendDetailsBtn.dataset;

    let success = true;
    try {
      const response = await apiClient('/sell/send-details', {
        body: { branchSlug, listingSlug },
      });
    } catch (error) {
      success = false;
    }

    setTimeout(() => {
      if (success) {
        sendDetailsBtn.innerHTML =
          'Details sent<i class="fa-regular fa-check fa-sm ms-2" aria-hidden="true"></i>';
      } else {
        enableButton(sendDetailsBtn);
        errorDisplay.classList.add('d-block');
      }
    }, 400);
  }
}
