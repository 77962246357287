import './shared/rails';

// eslint-disable-next-line import/no-unresolved
import '@client-side-validations/client-side-validations/src';
// eslint-disable-next-line import/no-unresolved
import '@client-side-validations/simple-form/src/index.bootstrap4';

// Import Bootstrap
import Dropdown from 'bootstrap/js/dist/dropdown';
import Collapse from 'bootstrap/js/dist/collapse';
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Tab from 'bootstrap/js/dist/tab';

// Import jQuery
import './jquery';
import autosize from 'autosize';

// import Stimulus controllers
import './controllers/public';

// import './admin/get-make-logo.js.erb';

// Import components
import './shared/address-lookup';
import './shared/cookie-consent';
import './shared/browser-update';
import './shared/scroll-to';
import './shared/lazy-load';
import { initAllTooltips } from './shared/util';
import { Form } from './shared/form';
import { ImageUpload } from './shared/image-upload';
import { ColorPicker } from './shared/color-picker';
import { Collapse as ERCollapse } from './shared/collapse';
import { Datepicker } from './shared/datepicker';

document.addEventListener('DOMContentLoaded', () => {
  // initialize tooltips
  const tooltips = initAllTooltips();

  // initialize date/time pickers
  if (document.querySelectorAll('.js-date-picker').length) {
    const dateTimePickers = document.querySelectorAll('.js-date-picker');
    if (dateTimePickers.length) {
      [...dateTimePickers].map((dateTimePicker) => new Datepicker(dateTimePicker));
    }
  }

  // initialize automatic resizing of textareas
  autosize(document.querySelectorAll('textarea'));

  // initialize image upload
  const imageUploadWrappers = document.querySelectorAll('.js-image-upload');
  if (imageUploadWrappers.length) {
    [...imageUploadWrappers].map((imageUploadWrapper) => new ImageUpload(imageUploadWrapper));
  }

  // initialize forms
  if (document.querySelectorAll('.js-form').length) {
    const forms = document.querySelectorAll('.js-form');
    if (forms.length) {
      [...forms].map((form) => new Form(form));
    }
  }

  // initialize color pickers
  if (document.querySelectorAll('.js-color-picker').length) {
    const colorPickers = document.querySelectorAll('.js-color-picker');
    if (colorPickers.length) {
      [...colorPickers].map((colorPicker) => new ColorPicker(colorPicker));
    }
  }

  // initialize collapse previews
  if (document.querySelectorAll('.js-collapse-wrapper').length) {
    const previewCollapseWrappers = document.querySelectorAll('.js-collapse-wrapper');
    if (previewCollapseWrappers.length) {
      [...previewCollapseWrappers].map(
        (previewCollapseWrapper) => new ERCollapse(previewCollapseWrapper)
      );
    }
  }

  // initialize copyUrlButton
  if (document.getElementById('copyUrlButton')) {
    const copyUrlButton = document.getElementById('copyUrlButton');
    copyUrlButton.addEventListener('click', function () {
      const currentUrl = window.location.href;

      navigator.clipboard
        .writeText(currentUrl)
        .then(function () {
          alert('URL copied to clipboard!');
        })
        .catch(function (error) {
          console.error('Failed to copy URL to clipboard: ', error);
        });
    });
  }
});
