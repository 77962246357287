import $ from 'jquery';

export class AddressLookup {
  constructor(lookup) {
    this.lookupInputWrapper = lookup.querySelector('.js-address-lookup-input-wrapper');
    this.lookupInput = lookup.querySelector('.js-address-lookup-input');
    this.lookupInputDefaultPlaceholder = this.lookupInput.placeholder;
    this.lookupOutputForm = lookup.querySelector('.js-address-lookup-output-form');
    this.manualEntryBtn = lookup.querySelector('.js-address-lookup-manual-entry-btn');
    this.changeAddressBtn = lookup.querySelector('.js-address-lookup-change-address-btn');
    this.countyMappings = {
      'Central Bedfordshire': 'Bedfordshire',
    };

    const autocompleteConfig = {
      fields: ['address_components', 'name', 'types'],
      componentRestrictions: {
        country: ['GB'],
      },
    };

    if (window.google) {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        this.lookupInput,
        autocompleteConfig
      );
    }

    this.setupEventListeners();
  }

  setupEventListeners() {
    // Hack preventing autocomplete on lookup field covering actual places dropdown
    const autocompleteAttrObserverHack = new MutationObserver(() => {
      autocompleteAttrObserverHack.disconnect();
      this.lookupInput.setAttribute('autocomplete', 'new-password');
    });

    autocompleteAttrObserverHack.observe(this.lookupInput, {
      attributes: true,
      attributeFilter: ['autocomplete'],
    });
    // Hack end

    if (window.google) {
      window.google.maps.event.addListener(this.autocomplete, 'place_changed', () =>
        this.handlePlaceSelection()
      );
    }

    if (this.manualEntryBtn) {
      this.manualEntryBtn.addEventListener('click', (e) => {
        // this.lookupInput.removeAttribute('required');
        this.lookupInput.value = '';
        this.showAddressForm();
      });
    }

    if (this.changeAddressBtn) {
      this.changeAddressBtn.addEventListener('click', (e) => {
        this.lookupInput.setAttribute('required', true);
        $(this.lookupInputWrapper).slideDown();
        $(this.changeAddressBtn).slideUp();
        $(this.lookupOutputForm).slideUp(() => {
          this.clearOutputForm();
        });
      });
    }

    document.addEventListener('customer-lookup:resultRetrieved', (e) => {
      // expand address form when existing customer lookup returns a result
      // essentially acts as if address lookup was performed, but it just uses existing customer address instead
      if (this.manualEntryBtn) {
        this.manualEntryBtn.click();
      }
    });
  }

  clearOutputForm() {
    const fields = this.lookupOutputForm.querySelectorAll('.form-control');
    fields.forEach((field) => {
      field.value = '';
    });
  }

  handlePlaceSelection() {
    const placeDetails = this.autocomplete.getPlace();
    this.lookupInput.placeholder = this.lookupInputDefaultPlaceholder;
    this.lookupInput.value = '';
    this.populateAddressIntoFields(placeDetails);
    this.showAddressForm();
  }

  extractAddressComponents(placeDetails) {
    const { name, address_components: addressComponents, types: placeTypes } = placeDetails;
    const line1arr = [];
    const addressData = addressComponents.reduce((acc, addressComponent) => {
      const { long_name: longName, short_name: shortName, types } = addressComponent;
      switch (types[0]) {
        case 'premise':
          line1arr.push(longName);
          break;
        case 'street_number':
          line1arr.push(longName);
          break;
        case 'street_address':
          line1arr.push(longName);
          break;
        case 'route':
          acc.line_2 = longName;
          break;
        case 'administrative_area_level_2':
          acc.county = longName;
          break;
        case 'postal_town':
          acc.post_town = longName;
          break;
        case 'postal_code':
          acc.postcode = longName;
          break;
        case 'country':
          acc.country = shortName;
          break;
        default:
          break;
      }
      return acc;
    }, {});

    addressData.line_1 = line1arr.join(', ');

    if (placeTypes.includes('establishment')) {
      // For business, prepend line 1 with its name
      const currentLine1 = addressData.line_1;
      addressData.line_1 = currentLine1 ? `${name}, ${currentLine1}` : name;
    }

    return addressData;
  }

  populateAddressIntoFields(placeDetails) {
    const addressInfo = this.extractAddressComponents(placeDetails);
    for (const addressField in addressInfo) {
      if ($(`.js-address-lookup-populate-${addressField}`).length) {
        $(`.js-address-lookup-populate-${addressField}`).val(addressInfo[addressField]);
      }
    }

    this.focusFirstEmptyField();
  }

  focusFirstEmptyField() {
    const addressFields = this.lookupOutputForm.querySelectorAll('.form-control');
    const firstEmptyField = [...addressFields].find((field) => field.value === '');
    if (firstEmptyField) {
      setTimeout(() => {
        firstEmptyField.focus();
      }, 0);
    }
  }

  showAddressForm() {
    this.lookupInput.removeAttribute('required');
    $(this.lookupInputWrapper).slideUp();
    $(this.changeAddressBtn).slideDown();
    $(this.lookupOutputForm).slideDown();
  }
}

// have to make it globally accessible on window object, as google places API needs this as a callback
window.initAutocomplete = () => {
  const lookup = document.querySelector('.js-address-lookup');
  if (lookup) {
    const addressLookup = new AddressLookup(lookup);
  }
};
